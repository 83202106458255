import { errorSwal } from "./plugins/sweetalert2.js";
import { post } from "./helper.js";

/**
 * Initialize new password check for the account security page
 *
 * @param {HTMLElement|Document} container The container element for the new password form
 *
 * TODO: Check if working correctly
 */
export const newPassword = function (container = document) {
    if (!container) {
        return;
    }

    const form = container.querySelector('form');
    const testResults = container.querySelector('#password-test');
    const inputNewPassword = container.querySelector('#new-password');
    const submitButton = container.querySelector('button[type="submit"]');

    // Hide all check status icons by default
    testResults.querySelectorAll('li i').forEach(icon => {
        icon.style.display = 'none';
    });

    // Disable the submit button (if it's not already disabled)
    submitButton.setAttribute('disabled', 'disabled');

    let handlePasswordInput = function (event) {
        // Disable the submit button
        submitButton.setAttribute('disabled', 'disabled');

        post(form.dataset.urlPasswordCheck, new FormData(form))
            .then((response) => {
                let hasFailedCheck = false;

                // If the response has the rules key, check if the password has passed all checks
                if('rules' in response) {
                    const rules = response.rules;

                    // Loop through all the rules and check if the password has passed all checks
                    for (const [key, status] of Object.entries(rules)) {
                        const listItem = testResults.querySelector(`li[data-testtype="${key}"]`);

                        // If the listItem is not found, skip this iteration
                        if(!listItem)
                            continue;

                        // Set the correct icon based on the check result
                        if(status) {
                            listItem.querySelector('i.fa-check').style.display = 'inline-block';
                            listItem.querySelector('i.fa-times').style.display = 'none';
                        } else {
                            hasFailedCheck = true;

                            listItem.querySelector('i.fa-check').style.display = 'none';
                            listItem.querySelector('i.fa-times').style.display = 'inline-block';
                        }
                    }
                } else {
                    hasFailedCheck = true;
                }

                // Enable or disable the submit button based on the check results
                if (hasFailedCheck)
                {
                    submitButton.setAttribute('disabled', 'disabled');
                }
                else
                {
                    submitButton.removeAttribute('disabled');
                }
            })
            .catch((error) => {
                console.error('Error checking password:', error);
                errorSwal.fire({
                    title: 'Fout bij controleren wachtwoord',
                    text: 'Er is een fout opgetreden bij het controleren van het wachtwoord. Probeer het later opnieuw.',
                    icon: 'error'
                });
            });

        // Show all check status icons
        testResults.querySelectorAll('li i.fa-times').forEach(icon => {
            icon.style.display = 'inline-block';
        });
    }

    // Check if the inputNewPassword or handlePasswordInput is not defined
    if (!inputNewPassword) {
        console.error('[App][newPassword] inputNewPassword or handlePasswordInput is not defined', {
            form,
        });
        return;
    }

    // Add event listeners for 'blur', 'keyup', 'paste', and 'focus' events
    ['blur', 'keyup', 'paste', 'focus'].forEach(eventType => {
        inputNewPassword.addEventListener(eventType, handlePasswordInput);
    });

    handlePasswordInput();
};

export default newPassword;
